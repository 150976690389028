<template>
  <div>
    

    <b-button class="btn-success mb-2" v-on:click="createNotification(0)">
      <feather-icon icon="AddIcon" />
      Create Notification
    </b-button>


    <!-- ALL ITEMS CARD -->
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="6" offset-md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search..."
                v-model="keyword"
                type="text"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- ALl Item table -->
      <b-table
        ref="refNewProductListTable"
        :items="notificationItems"
        responsive
        :fields="notificationTableColumn"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Auto ID [Sr.No] -->
        <template #cell(index)="data">
          {{ parseInt((pagination.currentPage-1) * pagination.perPage) + data.index + 1}}
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          {{ data.item.notification_id}}
        </template>

        <!-- Column: Item -->
        <template #cell(name)="data">
          <span class="text-nowrap">
            {{ data.item.notification_name}}
          </span>
        </template>

        <template #cell(heading)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.notification_heading }}
            </span>
          </b-media>
        </template>

        <template #cell(text)="data">
          <span class="text-nowrap">
            {{ data.item.notification_text}}
          </span>
        </template>

        <template #cell(image)="data">
          <span class="text-nowrap">
            {{ data.item.notification_text}}
          </span>
        </template>

        <template #cell(created)="data">
          <div :key="data">
            {{ getHumanDate(data.item.notification_created_at) }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon icon="EyeIcon" size="16" class="mx-1" :key="data" />
          </div>
        </template>
      </b-table>

      <b-pagination
        aria-controls="NewProductListTableId"
        v-model="pagination.currentPage"
        :total-rows="notificationsItemData.length"
        :per-page="pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
      
    </b-card>



    <!-- modal -->
      <b-modal
        id="modal-notificatoin"
        ref="my-modal"
        title="New Notification"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">

          <b-form-group
            :state="notificatoinTitleState"
            label="Notification Title"
            label-for="notificatoin-title"
            invalid-feedback="Notification Title is required"
          >
            <b-form-input
              id="notificatoin-title"
              v-model="notificatoinTitleModel"
              :state="notificatoinTitleState"
              autocomplete="off"
            />
          </b-form-group>

          <b-form-group
            :state="notificatoinHeadingState"
            label="Notification Heading"
            label-for="notificatoin-heading"
            invalid-feedback="Notification Heading is required"
          >
            <b-form-input
              id="notificatoin-heading"
              v-model="notificatoinHeadingModel"
              :state="notificatoinHeadingState"
              autocomplete="off"
            />
          </b-form-group>

        </form>
      </b-modal>


  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import feather from "feather-icons";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import storageService from "@/apiServices/storageService";
import NotificationServices from "@/apiServices/NotificationServices";
import { avatarText } from "@core/utils/filter";
import moment from "moment";
import axios from "axios";  

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,
    feather,
    FeatherIcon,
    moment,
  },
  setup() {
  },
  beforeMount() {
    this.loadAllItems();
  },
  directives: {
    Ripple,
  },
  computed: {
    notificationItems() {
      return this.keyword
        ? this.notificationsItemData.filter((x) => x.prod_designer.includes(this.keyword))
        : this.notificationsItemData;
    },
  },
  data() {
    return {
        notificationsItemData: [],
        keyword: "",
        pagination: {
            currentPage: 1,
            perPage: 5,
            perPageOptions: [10, 20, 30],
        },
        notificationTableColumn: [
            "index",
            { key: "id", label: "ID", sortable: true },
            { key: "name", label: "Notification Title", sortable: true },
            { key: "heading", label: "Heading", sortable: true },
            { key: "text", label: "Message", sortable: true },
            { key: "image", label: "Image", sortable: true },
            { key: "created", label: "Created", sortable: true },
            { key: "actions", label: "Actions" },
        ]
    };
  },
  methods: {
    
    loadAllItems() {
      console.log("Loaded All Notification Data");

      NotificationServices.getAllAdminNotifiaction()
        .then((response) => {
          console.log("All Noti Data: " + JSON.stringify(response.data.data));
          this.notificationsItemData = response.data.data;
          console.log(this.notificationsItemData);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    createNotification() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },


  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.swal2-html-container {
  color: white;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
